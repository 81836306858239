import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useRecoilState, useSetRecoilState } from "recoil"
import { modalState, alertState } from "../../../store"
import { Loading } from "../../loading"
import { useState } from "react"
import { MODAL } from "../../../constants"
import { mailchimpSubscribe } from "../../../handlers"
import { useIntl } from "../../../../plugins/gatsby-plugin-intl-with-root"
import * as Illust from "../../../assets/illust"

export function WaitlistSubscriptionModal() {
  const [modal, setModal] = useRecoilState(modalState)
  const setAlert = useSetRecoilState(alertState)
  const [formLoading, setFormLoading] = useState(false)
  const intl = useIntl()

  return (
    <System.Modal
      title={intl.formatMessage({ id: "ui.waitlist.title" })}
      desc={intl.formatMessage({ id: "ui.waitlist.description" })}
      closeOnClick={() => setModal("")}
      role="form"
      onSubmit={async values => {
        setFormLoading(true)
        const data = await mailchimpSubscribe(values)
        setModal("")
        if (data.result !== "success") {
          setAlert({
            show: true,
            kind: "negative",
            title: "error",
            message: data.msg,
            body: <Illust.Hammer width={100} />,
          })
        } else {
          setAlert({
            show: true,
            kind: "positive",
            title: "Thanks",
            message: data.msg,
            body: <Illust.Done width={100} />,
          })
        }
        setFormLoading(false)
      }}
      loadingSpinner={<Loading kind="inner" />}
      loading={formLoading}
      isOpen={modal === MODAL.WAIT_LIST}
      cancelText={intl.formatMessage({ id: "ui.cta.cancel" })}
      confirmText={intl.formatMessage({ id: "ui.cta.subscribe" })}
    >
      <System.Input
        name="email"
        label="E-MAIL"
        placeholder="Put your e-mail address"
        pattern="/^\S+@\S+$/"
      />
    </System.Modal>
  )
}
