import { styled } from "styletron-react"
import { motion } from "framer-motion"
import { HEADER_LOGO_HEIGHT } from "../../../constants"

export const MenuIconWrap = styled(motion.div, ({ $theme }) => {
  return {
    position: "fixed",
    top: "0px",
    right: $theme.sizing.scale900,
    height: `calc(${HEADER_LOGO_HEIGHT}px + ${$theme.sizing.scale900} * 2)`,
    display: "flex",
    justifyContent: "center",
    zIndex: $theme.zIndex.header,
    WebkitTapHighlightColor: "rgba(0,0,0,0)",
  }
})

export const Highlight = styled("div", ({ $theme }) => {
  return {
    ...$theme.typography.LabelMedium,
    color: $theme.colors.primary,
    fontWeight: 900,
  }
})
