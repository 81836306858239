import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { MenuIconWrap, Highlight } from "./styled-components"
import { MobileView } from "../../../utils"
import { navigate } from "../../../../plugins/gatsby-plugin-intl-with-root"
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil"
import { modalState, userState, mobileMenuState } from "../../../store"
import { MODAL } from "../../../constants"

export function MobileMenu() {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [mobileMenu, setMobileMenu] = useRecoilState(mobileMenuState)
  const setModal = useSetRecoilState(modalState)
  const user = useRecoilValue(userState)

  return (
    <MobileView>
      <System.Drawer
        isOpen={mobileMenu}
        onClickOutside={() => setMobileMenu(false)}
      >
        <System.Block
          padding={theme.sizing.scale900}
          backgroundColor={theme.colors.backgroundSecondary}
        >
          <System.ListItem
            onClick={() => {
              navigate("/courses")
              setMobileMenu(false)
            }}
          >
            <System.ListItemLabel>
              <System.LabelMedium>Courses</System.LabelMedium>
            </System.ListItemLabel>
          </System.ListItem>
          {!user.member && (
            <System.ListItem
              onClick={() => {
                navigate("/pricing")
                setMobileMenu(false)
              }}
            >
              <System.ListItemLabel>
                <System.LabelMedium>Pricing</System.LabelMedium>
              </System.ListItemLabel>
            </System.ListItem>
          )}
          {!user.email && (
            <System.ListItem
              onClick={() => {
                setModal(MODAL.SIGN_IN)
                setMobileMenu(false)
              }}
            >
              <System.ListItemLabel>
                <System.LabelMedium>Sign in</System.LabelMedium>
              </System.ListItemLabel>
            </System.ListItem>
          )}
          {user.email && (
            <System.ListItem
              onClick={() => {
                navigate("/account")
                setMobileMenu(false)
              }}
            >
              <System.ListItemLabel>
                <System.LabelMedium>Your account</System.LabelMedium>
              </System.ListItemLabel>
            </System.ListItem>
          )}
          {!user.member && (
            <System.ListItem
              onClick={() => {
                setModal(MODAL.BECOME_A_MEMBER)
                setMobileMenu(false)
              }}
            >
              <System.ListItemLabel>
                <Highlight {...sharedProps}>Become a Member</Highlight>
              </System.ListItemLabel>
            </System.ListItem>
          )}
        </System.Block>
      </System.Drawer>
      {/* MenuIcon */}
      <MenuIconWrap onClick={() => setMobileMenu(!mobileMenu)} {...sharedProps}>
        <System.Icons.DivMenu active={mobileMenu} />
      </MenuIconWrap>
    </MobileView>
  )
}

MobileMenu.defaultProps = {}
