import { styled } from "styletron-react"
import { KIND } from "./constants"

export const Wrap = styled("div", ({ $theme, $kind }) => {
  return {
    background: $theme.colors.backgroundPrimary,
    ...getKindStyles({ $theme, $kind }),
  }
})
export const LottieWrap = styled("div", {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "200px",
  height: "200px",
})

function getKindStyles({ $theme, $kind }) {
  switch ($kind) {
    case KIND.page:
      return {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0px",
        zIndex: $theme.zIndex.modal,
      }
    case KIND.inner:
      return {}
    default:
      return Object.freeze({})
  }
}
