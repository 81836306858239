import * as React from "react"
import * as Illust from "../../assets/illust"
import { loadFireStore } from "../../handlers"

export const updateUser = async (
  user,
  values,
  setFormLoading,
  setModal,
  setAlert,
  setUser
) => {
  try {
    setFormLoading(true)
    let doc: any = await loadFireStore("user", user.uid)
    await doc.update(values)
    setUser({
      ...user,
      name: values.name,
    })
    setFormLoading(false)
    setModal("")
    setAlert({
      show: true,
      kind: "positive",
      title: "Updated",
      message: "",
      body: <Illust.Done width={100} />,
    })
  } catch (error) {
    console.error("error - updateUser", error)
    setFormLoading(false)
    setModal("")
    setAlert({
      show: true,
      kind: "negative",
      title: "error",
      message: error.message,
      body: <Illust.Hammer width={100} />,
    })
  }
}
