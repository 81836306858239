import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useRecoilState, useSetRecoilState } from "recoil"
import { modalState, alertState } from "../../../store"
import { Loading } from "../../loading"
import { useState } from "react"
import { signIn } from "../../../handlers"
import { MODAL } from "../../../constants"
import { useIntl } from "../../../../plugins/gatsby-plugin-intl-with-root"

export function SignInModal() {
  const [modal, setModal] = useRecoilState(modalState)
  const setAlert = useSetRecoilState(alertState)
  const [formLoading, setFormLoading] = useState(false)
  const intl = useIntl()

  return (
    <System.Modal
      title={intl.formatMessage({ id: "ui.auth.sign_in" })}
      closeOnClick={() => setModal("")}
      role="form"
      onSubmit={values => signIn(values, setFormLoading, setModal, setAlert)}
      loadingSpinner={<Loading kind="inner" />}
      loading={formLoading}
      isOpen={modal === MODAL.SIGN_IN}
      cancelText={intl.formatMessage({ id: "ui.cta.cancel" })}
      confirmText={intl.formatMessage({ id: "ui.auth.sign_in" })}
      desc={intl.formatMessage({ id: "ui.auth.send_a_link" })}
    >
      <System.Input
        name="email"
        label="E-MAIL"
        caption=""
        placeholder="Put your e-mail address"
        pattern="/^\S+@\S+$/"
      />
    </System.Modal>
  )
}
