import * as React from "react"
import { useState } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import {
  modalState,
  alertState,
  userState,
  membershipState,
} from "../../../store"
import { StripeProvider, Elements, injectStripe } from "react-stripe-elements"
import { STRIPE_API_KEY, MODAL } from "../../../constants"
import { subsrcibe } from "../../../handlers"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { Loading } from "../../loading"
import { isClient, PriceCardTitle } from "../../../utils"
import { StripeCardInput } from "../../../components/stripe-card-input"
import { useIntl } from "../../../../plugins/gatsby-plugin-intl-with-root"

const BecomeAMemberComp = props => {
  const [modal, setModal] = useRecoilState(modalState)
  const [formLoading, setFormLoading] = useState(false)
  const [alert, setAlert] = useRecoilState(alertState)
  const [user, setUser] = useRecoilState(userState)
  const membership = useRecoilValue(membershipState)
  const theme = useTheme()
  const intl = useIntl()

  return (
    <System.Modal
      title={
        <>
          {intl.formatMessage({ id: "ui.cta.become_a_member" })}
          <System.Spacing height="scale300" />
          <PriceCardTitle
            price={`$${membership.price / 100}`}
            salePrice={`$${membership.sale_price / 100}/${intl.formatMessage({
              id: `ui.price.${membership.interval}`,
            })}`}
          />
        </>
      }
      closeOnClick={() => setModal("")}
      role="form"
      onSubmit={values =>
        subsrcibe(
          user,
          values,
          membership, // Daily Test
          props.stripe,
          setFormLoading,
          setModal,
          setAlert,
          setUser
        )
      }
      loadingSpinner={<Loading kind="inner" />}
      loading={formLoading}
      isOpen={modal === MODAL.BECOME_A_MEMBER}
      desc={
        <>
          <System.LabelSmall color="primary">
            <strong>
              {intl.formatMessage({
                id: `pages.pricing.sales.open_sale`,
              })}
            </strong>
          </System.LabelSmall>
          <System.Spacing height="scale300" />-{" "}
          {intl.formatMessage({
            id: `pages.pricing.membership.${membership.key}.description`,
          })}
          <System.Spacing height="scale100" />-{" "}
          {intl.formatMessage({
            id: `pages.pricing.membership.benefits.discussion`,
          })}
        </>
      }
      confirmText={intl.formatMessage({ id: "ui.cta.subscribe" })}
      cancelText={intl.formatMessage({ id: "ui.cta.cancel" })}
    >
      {!user.email && (
        <System.Input
          name="email"
          label="E-MAIL"
          placeholder="Put your e-mail address"
          pattern="/^\S+@\S+$/"
        />
      )}
      {!user.email && <System.Block height={theme.sizing.scale900} />}
      <StripeCardInput />
    </System.Modal>
  )
}

const EnhancedBecomeAMemberComp = injectStripe(BecomeAMemberComp)

export const BecomeAMemberModal = () => {
  return (
    isClient && (
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <Elements>
          <EnhancedBecomeAMemberComp />
        </Elements>
      </StripeProvider>
    )
  )
}
