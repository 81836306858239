import * as React from "react"
import { useTheme } from "@harborschool/lighthouse"
import { styled } from "styletron-react"
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil"
import { alertState, constructionState, loadingState } from "../../store"
import { Loading } from "../loading"
import { KIND } from "../loading/constants"
import * as System from "@harborschool/lighthouse"
import { mailchimpSubscribe } from "../../handlers"
import * as Illust from "../../assets/illust"
import { useState } from "react"
import { tempPassword } from "../../constants"

export const Wrap = styled("div", ({ $theme, $kind }) => {
  return {
    background: $theme.colors.backgroundPrimary,
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0px",
    zIndex: $theme.zIndex.modal,
  }
})

export const Center = styled("div", ({ $theme, $kind }) => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
    padding: `0 ${$theme.sizing.scale1200}`,
    boxSizing: "border-box",
  }
})

export function ConstructionMode() {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const setAlert = useSetRecoilState(alertState)
  const [construction, setConstruction] = useRecoilState(constructionState)
  const [admin, setAdmin] = useState(false)

  return (
    construction && (
      <Wrap {...sharedProps}>
        <Center {...sharedProps}>
          {!admin && (
            <>
              <System.DisplaySmall>Under 👨🏻‍🌾 Construction</System.DisplaySmall>
              <System.Spacing height="scale700" />
              <System.Form
                onSubmit={async values => {
                  const data = await mailchimpSubscribe(values)
                  if (data.result !== "success") {
                    setAlert({
                      show: true,
                      kind: "negative",
                      title: "error",
                      message: data.msg,
                      body: <Illust.Hammer width={100} />,
                    })
                  } else {
                    setAlert({
                      show: true,
                      kind: "positive",
                      title: "Thanks",
                      message: data.msg,
                      body: <Illust.Done width={100} />,
                    })
                  }
                }}
                formBody={
                  <System.Input
                    name="email"
                    label="E-MAIL"
                    placeholder="Join the waitlist!"
                    pattern="/^\S+@\S+$/"
                  />
                }
                formFooter={
                  <System.Block display="flex" justifyContent="flex-end">
                    <System.Button
                      type="button"
                      kind="secondary"
                      onClick={() => setAdmin(true)}
                    >
                      Admin
                    </System.Button>
                    <System.Spacing width="scale400" display="inline-block" />
                    <System.Button type="submit">Subscribe</System.Button>
                  </System.Block>
                }
              />
            </>
          )}
          {admin && (
            <>
              <System.DisplaySmall>Type Password 🔒</System.DisplaySmall>
              <System.Spacing height="scale700" />
              <System.Form
                onSubmit={({ password }) => {
                  if (password === tempPassword) {
                    setAlert({
                      show: true,
                      kind: "positive",
                      title: "Success",
                      message: "Success to enter",
                      body: <Illust.Done width={100} />,
                    })
                    setConstruction(false)
                  } else
                    setAlert({
                      show: true,
                      kind: "negative",
                      title: "error",
                      message: "Wrong Password",
                      body: <Illust.Hammer width={100} />,
                    })
                }}
                formBody={
                  <System.Input
                    name="password"
                    label=""
                    placeholder="Type the password"
                    type="password"
                    // pattern="/^\S+@\S+$/"
                  />
                }
                formFooter={
                  <System.Block display="flex" justifyContent="flex-end">
                    <System.Button
                      type="button"
                      kind="secondary"
                      onClick={() => setAdmin(true)}
                    >
                      Admin
                    </System.Button>
                    <System.Spacing width="scale400" display="inline-block" />
                    <System.Button type="submit">Subscribe</System.Button>
                  </System.Block>
                }
              />
            </>
          )}
        </Center>
      </Wrap>
    )
  )
}

ConstructionMode.defaultProps = {}
