import * as React from "react"
import { useState } from "react"
import { useRecoilState } from "recoil"
import { navigate } from "../../../../plugins/gatsby-plugin-intl-with-root"
import { updateNotificationQue } from "../../../handlers"
import { userState } from "../../../store"
import { removeValueFromArray } from "../../../utils"
import * as Illust from "../../../assets/illust"
import { NotificationNumber, Overlay } from "./styled-components"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { AnimatePresence } from "framer-motion"

export function Notification() {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [user, setUser] = useRecoilState(userState)
  const [notifications, setNotifications] = useState(user.notifications)
  const [folded, setFolded] = useState(true)

  async function updateNotification(newNotifications) {
    setNotifications(newNotifications)
    setUser({ ...user, notifications: newNotifications })
    await updateNotificationQue({
      userId: user.uid,
      notifications: newNotifications,
    })
  }

  const motionProps = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: theme.animation.timing200,
        ease: theme.animation.easeInCurve,
      },
    },
    exit: {
      opacity: 0,
    },
  }

  return (
    <div>
      <AnimatePresence>
        {!folded && (
          <Overlay
            onClick={() => setFolded(true)}
            {...sharedProps}
            {...motionProps}
          />
        )}
      </AnimatePresence>
      <System.Block position="relative" onClick={() => setFolded(false)}>
        <Illust.Bell />
        {notifications && notifications.length > 0 && (
          <NotificationNumber {...sharedProps}>
            <System.LabelSmall color="contentInversePrimary">
              {notifications.length}
            </System.LabelSmall>
          </NotificationNumber>
        )}
        {!folded && (
          <System.Block
            position="absolute"
            top="26px"
            right="0px"
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            width="500px"
          >
            {(!notifications || notifications.length === 0) && (
              <System.ListItem
                endEnhancer={
                  <System.Block marginLeft="scale700">
                    <Illust.Check />
                  </System.Block>
                }
              >
                <System.ListItemLabel>
                  <System.LabelMedium whiteSpace="nowrap">
                    No Notification
                  </System.LabelMedium>
                </System.ListItemLabel>
              </System.ListItem>
            )}
            {notifications && notifications.length > 0 && (
              <>
                <System.Block>
                  {notifications.map((item, id) => (
                    <System.ListItem
                      key={id}
                      endEnhancer={
                        <System.Block marginLeft="scale700">
                          <Illust.Check />
                        </System.Block>
                      }
                      onClick={async () => {
                        const resolved = removeValueFromArray(
                          item,
                          notifications
                        )
                        await updateNotification(resolved)
                        navigate(item.slug)
                      }}
                    >
                      <System.ListItemLabel>
                        <System.LabelMedium>
                          <span style={{ textTransform: "capitalize" }}>
                            {item.kind}
                          </span>{" "}
                          Notification ({item.title})
                        </System.LabelMedium>
                        <System.ParagraphXSmall whiteSpace="nowrap">
                          {item.name} replys to your comment
                        </System.ParagraphXSmall>
                      </System.ListItemLabel>
                    </System.ListItem>
                  ))}
                </System.Block>
                <System.Spacing height="scale500" />
                <System.Button
                  kind="secondary"
                  shape="pill"
                  onClick={() => updateNotification([])}
                >
                  Read all
                </System.Button>
              </>
            )}
          </System.Block>
        )}
      </System.Block>
    </div>
  )
}
