import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useRecoilState, useSetRecoilState } from "recoil"
import { modalState, alertState, userState } from "../../../store"
import { Loading } from "../../loading"
import { useState } from "react"
import { updateUser } from "../../../handlers/user/update-user"
import { MODAL } from "../../../constants"

export function UpdateNameModal() {
  const [modal, setModal] = useRecoilState(modalState)
  const setAlert = useSetRecoilState(alertState)
  const [formLoading, setFormLoading] = useState(false)
  const [user, setUser] = useRecoilState(userState)

  return (
    <System.Modal
      title="Update Name"
      closeOnClick={() => setModal("")}
      role="form"
      onSubmit={values =>
        updateUser(user, values, setFormLoading, setModal, setAlert, setUser)
      }
      loadingSpinner={<Loading kind="inner" />}
      loading={formLoading}
      isOpen={modal === MODAL.UPDATE_NAME}
      cancelText="Cancel"
      confirmText="Save"
      desc=""
    >
      <System.Input
        name="name"
        label=""
        placeholder="Your New Name"
        defaultValue={user.name}
        caption=""
      />
    </System.Modal>
  )
}
