import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { styled } from "styletron-react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import * as Brand from "../../assets/brand"
import {
  ShareFlexPropsWithChildren,
  DesktopView,
  MobileView,
} from "../../utils"

const Footer = () => {
  const theme = useTheme()
  const sharedProps = { $theme: theme }

  return (
    <System.Block
      position="relative"
      height="320px"
      color={theme.colors.contentSecondary}
      backgroundColor={theme.colors.backgroundTertiary}
    >
      <DesktopView>
        <System.FlexBox
          justifyContent="flex-end"
          alignItems="flex-end"
          position="absolute"
          columnGap={theme.sizing.scale900}
          right={theme.sizing.scale800}
          bottom={theme.sizing.scale800}
        >
          <FooterContents />
        </System.FlexBox>
      </DesktopView>
      <MobileView>
        <System.FlexBox
          justifyContent="flex-end"
          alignItems="flex-end"
          position="absolute"
          right={theme.sizing.scale800}
          bottom={theme.sizing.scale800}
          flexDirection="column"
          rowGap={theme.sizing.scale700}
        >
          <FooterContents />
        </System.FlexBox>
      </MobileView>
    </System.Block>
  )
}

Footer.defaultProps = {}

export default Footer

function FooterContents(props) {
  const theme = useTheme()
  return (
    <ShareFlexPropsWithChildren {...props}>
      {/* Copyrights */}
      <System.FlexItem
        overrides={{
          Block: {
            ...theme.typography.ParagraphSmall,
            [theme.mediaQuery.small]: {
              display: "block",
              marginBottom: theme.sizing.scale700,
            },
          },
        }}
      >
        © {new Date().getFullYear()} Harbor School{" "}
        <DesktopView as="span">
          - Proudly built with{" "}
          <a
            href="https://lighthouse.harbor.school"
            target="_blank"
            style={{
              color: "inherit",
            }}
          >
            Lighthouse Design System
          </a>
        </DesktopView>
      </System.FlexItem>
      <MobileView>
        <System.Spacing height="scale300" />
      </MobileView>
      {/* SNS Icon & Logo */}
      <System.FlexBox columnGap={theme.sizing.scale900}>
        <System.FlexItem>
          <System.FlexBox
            alignItems="flex-end"
            columnGap={theme.sizing.scale500}
          >
            {/* Twitter */}
            <System.FlexItem
              as="a"
              href="https://twitter.com/harborsch"
              target="_blank"
            >
              <System.Icon
                viewBox="0 0 18 18"
                width={theme.sizing.scale800}
                color={theme.colors.contentSecondary}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18 0H0V18H18V0ZM13.478 7.00702C13.478 10.053 11.156 13.572 6.91199 13.572C5.612 13.572 4.39703 13.19 3.38 12.542C3.56 12.564 3.74402 12.573 3.92902 12.573C5.008 12.573 6.00299 12.204 6.79498 11.583C5.78699 11.565 4.93201 10.899 4.64001 9.98102C4.77899 10.008 4.92303 10.022 5.07202 10.022C5.28302 10.022 5.48499 9.995 5.67902 9.94101C4.62598 9.729 3.83002 8.793 3.83002 7.677V7.64502C4.14001 7.81702 4.49603 7.92001 4.87402 7.93402C4.25702 7.52002 3.84802 6.81302 3.84802 6.01202C3.84802 5.58902 3.96002 5.19302 4.15802 4.85101C5.297 6.246 6.99799 7.16901 8.914 7.263C8.87903 7.09201 8.85602 6.91702 8.85602 6.737C8.85602 5.46301 9.89099 4.42801 11.164 4.42801C11.826 4.42801 12.425 4.707 12.848 5.15701C13.374 5.05301 13.869 4.86502 14.315 4.599C14.139 5.13901 13.775 5.59399 13.298 5.87701C13.766 5.819 14.211 5.69702 14.625 5.513C14.315 5.97601 13.923 6.38501 13.473 6.70901C13.478 6.80902 13.478 6.90701 13.478 7.00702Z"
                />
              </System.Icon>
            </System.FlexItem>
            {/* Medium */}
            <System.FlexItem
              as="a"
              href="https://medium.com/harbor-school"
              target="_blank"
            >
              <System.Icon
                viewBox="0 0 18 18"
                width={theme.sizing.scale800}
                color={theme.colors.contentSecondary}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 0H17.9618V18H0V0ZM4.28625 6.01987V11.3636C4.3026 11.4587 4.29635 11.5563 4.26801 11.6486C4.23968 11.7408 4.19004 11.8251 4.12313 11.8946L2.85525 13.4359V13.6384H6.44963V13.4359L5.18175 11.8946C5.11452 11.8252 5.06389 11.7415 5.03369 11.6497C5.00349 11.5579 4.99449 11.4605 5.00738 11.3647V6.74213L8.16187 13.6384H8.52862L11.2376 6.74213V12.2389C11.2376 12.3851 11.2376 12.4144 11.142 12.51L10.1678 13.4584V13.6609H14.8995V13.4584L13.959 12.5325C13.9184 12.5014 13.887 12.4598 13.8682 12.4123C13.8494 12.3647 13.8438 12.313 13.8521 12.2625V5.45963C13.8438 5.40916 13.8494 5.35739 13.8682 5.30983C13.887 5.26228 13.9184 5.22073 13.959 5.18962L14.922 4.26375V4.06125H11.5875L9.21037 10.0035L6.50588 4.06125H3.00713V4.26375L4.13438 5.625C4.24463 5.72512 4.30087 5.8725 4.28625 6.01987Z"
                />
              </System.Icon>
            </System.FlexItem>
          </System.FlexBox>
        </System.FlexItem>
        <System.FlexItem>
          <Brand.LogoText
            height={theme.sizing.scale800}
            color={theme.colors.contentSecondary}
          />
        </System.FlexItem>
      </System.FlexBox>
    </ShareFlexPropsWithChildren>
  )
}
