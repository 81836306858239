import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Navigation } from "./navigation"
import Footer from "./footer"
import "./css/layout.css"
import { ModalContainer } from "./modal-container"
import { LoadingContainer } from "./loading-container"
import { useUser } from "../../hooks/user"
import { Log } from "../../utils"
import { loadFirebase } from "../../handlers"
import { MobileMenu } from "./mobile-menu/mobile-menu"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { ConstructionMode } from "./construction-mode"
import { useRecoilValue } from "recoil"
import { constructionState } from "../../store"

loadFirebase()
const Layout = ({ children, hiddenFooter }) => {
  const theme = useTheme()
  const construction = useRecoilValue(constructionState)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  Log.info(`RenderCount/Load Layout`)
  useUser()
  return (
    <System.Block backgroundColor={theme.colors.backgroundPrimary}>
      {(process.env.PRODUCTION !== "true" ||
        (process.env.PRODUCTION === "true" && !construction)) && (
        <>
          <Navigation />
          <main>{children}</main>
          {!hiddenFooter && <Footer />}
          <ModalContainer />
          <LoadingContainer />
        </>
      )}
      {process.env.PRODUCTION === "true" && <ConstructionMode />}
      <MobileMenu />
    </System.Block>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hiddenFooter: PropTypes.bool,
}

export default Layout
