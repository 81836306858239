import * as React from "react"
import { useState } from "react"
import { useRecoilState } from "recoil"
import { modalState, alertState, userState } from "../../../store"
import { updateSubscription } from "../../../handlers"
import * as System from "@harborschool/lighthouse"
import { Loading } from "../../loading"
import { MODAL } from "../../../constants"

export const ResumeSubscriptionModal = () => {
  const [modal, setModal] = useRecoilState(modalState)
  const [formLoading, setFormLoading] = useState(false)
  const [alert, setAlert] = useRecoilState(alertState)
  const [user, setUser] = useRecoilState(userState)

  return (
    <System.Modal
      title="Resume Subscription"
      closeOnClick={() => setModal("")}
      role="form"
      onSubmit={values =>
        updateSubscription(
          user,
          {
            pause_collection: "",
          },
          setFormLoading,
          setModal,
          setAlert,
          setUser
        )
      }
      loadingSpinner={<Loading kind="inner" />}
      loading={formLoading}
      isOpen={modal === MODAL.RESUME_SUBSCRIPTION}
      desc="Are you sure want to resume Subscriptions?"
      confirmText="Confirm"
      cancelText="Cancel"
    />
  )
}
