import * as React from "react"
import { useState } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { modalState, alertState, userState } from "../../../store"
import { subsrcibe, updatePayment, updateSubscription } from "../../../handlers"
import * as System from "@harborschool/lighthouse"
import { Loading } from "../../loading"
import { isClient } from "../../../utils"
import { MODAL } from "../../../constants"

export const PauseSubscriptionModal = () => {
  const [modal, setModal] = useRecoilState(modalState)
  const [formLoading, setFormLoading] = useState(false)
  const [alert, setAlert] = useRecoilState(alertState)
  const [user, setUser] = useRecoilState(userState)

  return (
    <System.Modal
      title="Pause Subscription"
      closeOnClick={() => setModal("")}
      role="form"
      onSubmit={values =>
        updateSubscription(
          user,
          {
            pause_collection: {
              behavior: "keep_as_draft",
            },
          },
          setFormLoading,
          setModal,
          setAlert,
          setUser
        )
      }
      loadingSpinner={<Loading kind="inner" />}
      loading={formLoading}
      isOpen={modal === MODAL.PAUSE_SUBSCRIPTION}
      desc="Are you sure want to pause Subscriptions?"
      confirmText="Confirm"
      cancelText="Cancel"
    />
  )
}
