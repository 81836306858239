import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { styled } from "styletron-react"
import { CardElement } from "react-stripe-elements"

const CardWrap = styled("div", ({ $theme }) => {
  return {
    padding: `${$theme.sizing.scale600} ${$theme.sizing.scale700}`,
    appearance: "none",
    boxShadow: `inset 0px 0px 0px 1px ${$theme.colors.backgroundTertiary}`, // inside border
    background: $theme.colors.backgroundSecondary,
    ...$theme.typography.ParagraphMedium,
  }
})

export const StripeCardInput = () => {
  const theme = useTheme()
  const sharedProps = { $theme: theme }

  return (
    <CardWrap {...sharedProps}>
      <CardElement />
    </CardWrap>
  )
}

StripeCardInput.defaultProps = {}
