import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { styled } from "styletron-react"
import { useRecoilValue, useRecoilState } from "recoil"
import {
  modalState,
  alertState,
  userState,
  selectState,
  mobileMenuState,
} from "../../../store"
import { Loading } from "../../loading"
import { useState } from "react"
import { signIn } from "../../../handlers"
import { BecomeAMemberModal } from "./become-a-member-modal"
import { SignInModal } from "./sign-in-modal"
import { UpdateNameModal } from "./update-name-modal"
import { UpdatePaymentModal } from "./update-payment-modal"
import { PauseSubscriptionModal } from "./pause-subscription-modal"
import { ResumeSubscriptionModal } from "./resume-subscription-modal"
import { WaitlistSubscriptionModal } from "./waitlist-subscription-modal"
import { AnimatePresence, motion } from "framer-motion"

const Overlay = styled(motion.div, ({ $theme }) => {
  return {
    boxShadow: $theme.lighting.overlay500,
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: "0px",
    zIndex: $theme.zIndex.overlay,
  }
})

export function ModalContainer() {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const modal = useRecoilValue(modalState)
  const [alert, setAlert] = useRecoilState(alertState)
  const select = useRecoilValue(selectState)
  const mobileMenu = useRecoilValue(mobileMenuState)

  const motionProps = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: theme.animation.timing200,
        ease: theme.animation.easeInCurve,
      },
    },
    exit: {
      opacity: 0,
    },
  }

  return (
    <>
      <AnimatePresence>
        {(modal || alert.show || select || mobileMenu) && (
          <Overlay {...sharedProps} {...motionProps} />
        )}
      </AnimatePresence>
      {/* Sign In Modal */}
      <SignInModal />
      {/* Become a Member Modal */}
      <BecomeAMemberModal />
      {/* Update Name Modal */}
      <UpdateNameModal />
      {/* Update Name Modal */}
      <UpdatePaymentModal />
      {/* Pause Subscription Modal */}
      <PauseSubscriptionModal />
      {/* Resume Subscription Modal */}
      <ResumeSubscriptionModal />
      {/* Mail Subscription Modal */}
      <WaitlistSubscriptionModal />
      {/* Alert */}
      <System.Alert
        show={alert.show}
        title={alert.title}
        message={alert.message}
        kind={alert.kind}
        body={alert.body}
        closeOnClick={() =>
          setAlert({
            ...alert,
            show: false,
          })
        }
      />
    </>
  )
}

ModalContainer.defaultProps = {}
