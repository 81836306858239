import * as React from "react"
import { useTheme } from "@harborschool/lighthouse"
import { styled } from "styletron-react"
import { useRecoilValue } from "recoil"
import { loadingState } from "../../store"
import { Loading } from "../loading"
import { KIND } from "../loading/constants"

export function LoadingContainer() {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const loading = useRecoilValue(loadingState)

  return (
    (loading === "loading" || loading === "before") && (
      <Loading kind={KIND.page} />
    )
  )
}

LoadingContainer.defaultProps = {}
