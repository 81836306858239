import * as React from "react"
import Lottie from "react-lottie"
import { Wrap, LottieWrap } from "./styled-components"
import * as animationData from "./loading_walking.json"
import { useTheme } from "@harborschool/lighthouse"
import { KIND } from "./constants"

const defaultOptions = {
  loop: true,
  autoplay: true,
  // @ts-ignore
  animationData: animationData.default || animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

export const Loading: React.FC<LoadingProps> = ({ kind }) => {
  const theme = useTheme()
  const sharedProps = { $theme: theme, $kind: kind }
  return (
    <Wrap {...sharedProps}>
      <LottieWrap>
        <Lottie options={defaultOptions} />
      </LottieWrap>
    </Wrap>
  )
}

export interface LoadingProps {
  kind?: keyof typeof KIND
}

Loading.defaultProps = {
  kind: KIND.inner,
}
