import { styled } from "styletron-react"
import { motion } from "framer-motion"

export const NotificationNumber = styled("div", ({ $theme }) => {
  return {
    width: $theme.sizing.scale650,
    height: $theme.sizing.scale650,
    background: $theme.colors.primary,
    borderRadius: "1000px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "-6px",
    right: "-9px",
  }
})

export const Overlay = styled(motion.div, ({ $theme }) => {
  return {
    boxShadow: $theme.lighting.overlay500,
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: "0px",
    left: "0px",
  }
})
