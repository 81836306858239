import {
  Link,
  navigate,
} from "../../../../plugins/gatsby-plugin-intl-with-root"
import React from "react"
import { styled } from "styletron-react"
import { useTheme, StyledLink } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"
import * as Brand from "../../../assets/brand"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { modalState, userState } from "../../../store"
import { Log, DesktopView } from "../../../utils"
import { MODAL, HEADER_LOGO_HEIGHT } from "../../../constants"
import { useScrollDirection } from "../../../hooks"
import { motion } from "framer-motion"
import { SCROLL_DIRECTION } from "../../../hooks/post-contents/use-scroll-direction"
import { Notification } from "./notification"

const Fixed = styled(motion.div, ({ $theme }) => {
  return {
    position: "fixed",
    width: "100%",
    zIndex: $theme.zIndex.header,
    background: "red",
  }
})
const Mock = styled("div", {
  opacity: 0,
})

export const Navigation = () => {
  const theme = useTheme()
  const scrollDir = useScrollDirection({ thresholdPixels: 100 })
  const sharedProps = { $theme: theme }
  const motionProps = {
    animate: {
      y: scrollDir === SCROLL_DIRECTION.DOWN ? "-100%" : "0",
    },
    transition: {
      ease: theme.animation.easeInCurve,
      duration: theme.animation.timing200,
    },
  }

  Log.info(`RenderCount/Header`)

  return (
    <>
      <Fixed {...sharedProps} {...motionProps}>
        <System.Block
          backgroundColor={theme.colors.backgroundPrimary}
          overrides={{
            Block: {
              padding: `0 ${theme.sizing.scale2400}`,
              [theme.mediaQuery.xLarge]: {
                padding: "0px",
              },
            },
          }}
        >
          <NavigationComp />
        </System.Block>
      </Fixed>
      <Mock id="mock-header">
        <NavigationComp />
      </Mock>
    </>
  )
}

const NavigationComp = () => {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const setModal = useSetRecoilState(modalState)
  const user = useRecoilValue(userState)

  return (
    <System.HeaderNavigation>
      <Link to="/">
        <Brand.LogoText
          height={HEADER_LOGO_HEIGHT}
          color={theme.colors.primary}
        />
      </Link>
      <DesktopView>
        <System.Block display="flex" alignItems="center">
          <System.HeaderNavigationItem
            onClick={() => navigate("/courses")}
            {...sharedProps}
          >
            <StyledLink>Courses</StyledLink>
          </System.HeaderNavigationItem>
          {!user.member && (
            <System.HeaderNavigationItem
              onClick={() => navigate("/pricing")}
              {...sharedProps}
            >
              <StyledLink>Pricing</StyledLink>
            </System.HeaderNavigationItem>
          )}
          {!user.email && (
            <System.HeaderNavigationItem
              onClick={() => setModal(MODAL.SIGN_IN)}
              {...sharedProps}
            >
              <StyledLink>Sign in</StyledLink>
            </System.HeaderNavigationItem>
          )}
          {user.email && (
            <System.HeaderNavigationItem
              onClick={() => navigate("/account")}
              {...sharedProps}
            >
              <StyledLink>Your account</StyledLink>
            </System.HeaderNavigationItem>
          )}
          {!user.member && (
            <System.HeaderNavigationItem
              onClick={() => setModal(MODAL.BECOME_A_MEMBER)}
              {...sharedProps}
            >
              <StyledLink highlight>Become a Member</StyledLink>
            </System.HeaderNavigationItem>
          )}
          {user.member && (
            <System.HeaderNavigationItem {...sharedProps}>
              <Notification />
            </System.HeaderNavigationItem>
          )}
        </System.Block>
      </DesktopView>
    </System.HeaderNavigation>
  )
}
