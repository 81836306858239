import * as React from "react"
import { useState } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { modalState, alertState, userState } from "../../../store"
import { StripeProvider, Elements, injectStripe } from "react-stripe-elements"
import { STRIPE_API_KEY, MODAL } from "../../../constants"
import { subsrcibe, updatePayment } from "../../../handlers"
import * as System from "@harborschool/lighthouse"
import { Loading } from "../../loading"
import { isClient } from "../../../utils"
import { StripeCardInput } from "../../../components/stripe-card-input"

const UpdatePaymentComp = props => {
  const [modal, setModal] = useRecoilState(modalState)
  const [formLoading, setFormLoading] = useState(false)
  const [alert, setAlert] = useRecoilState(alertState)
  const [user, setUser] = useRecoilState(userState)

  return (
    <System.Modal
      title="Update Payment"
      closeOnClick={() => setModal("")}
      role="form"
      onSubmit={values =>
        updatePayment(
          user,
          props.stripe,
          setFormLoading,
          setModal,
          setAlert,
          setUser
        )
      }
      loadingSpinner={<Loading kind="inner" />}
      loading={formLoading}
      isOpen={modal === MODAL.UPDATE_PAYMENT}
      desc=""
      confirmText="Save"
      cancelText="Cancel"
    >
      <StripeCardInput />
    </System.Modal>
  )
}

const EnhancedUpdatePaymentComp = injectStripe(UpdatePaymentComp)

export const UpdatePaymentModal = () => {
  return (
    isClient && (
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <Elements>
          <EnhancedUpdatePaymentComp />
        </Elements>
      </StripeProvider>
    )
  )
}
